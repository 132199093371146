import React from "react"

import "./contact-form-styles.scss"

import Button from "../Ghost-Button/button"

import ContactFormRobot from "../../images/ghost-service-scenario-5.svg"

const GhostContactForm = () => {
  return (
    <div className="ghost-contact-form-ultra-container">
      <div className="ghost-robot-content-container">
        <img src={ContactFormRobot} alt="Contact Form Robot" />
        <h3>
          Got Questions?
          <br />
          Let's talk.
        </h3>
      </div>

      <form
        className="ghost-contact-form"
        method="POST"
        action="/index.html"
        name="contact"
        netlify
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="form-name" value="contact" />
        <div className="ghost-form-group-input">
          <input
            className="ghost-form-input"
            type="text"
            name="name"
            aria-label="Name"
            required
            autoComplete="off"
          />
          <label className="shrink form-input-label">
            <span className="content-name">NAME</span>
          </label>
        </div>
        <div className="ghost-form-group-input">
          <input
            className="ghost-form-input"
            type="email"
            name="email"
            aria-label="Name"
            required
            autoComplete="off"
          />
          <label className="shrink form-input-label">
            <span className="content-name">EMAIL</span>
          </label>
        </div>
        <div className="ghost-form-group-textarea">
          <textarea
            className="ghost-form-textarea"
            type="text"
            name="message"
            aria-label="Message"
            required
            autoComplete="off"
            rows="5"
          />
          <label className="shrinkTwo form-textarea-label">
            <span className="textarea-content-name">MESSAGE</span>
          </label>
        </div>

        <div className="ghost-button-area">
          <Button label="Send Contact Form" cta="Send" type="submit" />
        </div>
      </form>
    </div>
  )
}

export default GhostContactForm
