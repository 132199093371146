import React, { Fragment } from "react"

import ProjectList from "../components/ProjectList/project-list"
import Contact from "../components/New-Contact-Form/new-contact-form"

import "../styles/projectsPageStyles.scss"

import {
  codamProject,
  idealProject,
  infoEmergingIndustriesProject,
  agileProjectManagementProject,
  takeawayProject,
  hanUniversityProject,
  lloydsEchoProject,
} from "../constants/new-ghost-projects"

const ProjectData = [
  lloydsEchoProject,
  codamProject,
  idealProject,
  infoEmergingIndustriesProject,
  agileProjectManagementProject,
  takeawayProject,
  hanUniversityProject,
]

const ProjectsPage = () => {
  return (
    <Fragment>
      <div className="ap-projects-page-container">
        {/* <div className="ap-projects-page-title"> <h1>Projects</h1> </div> */}
        <ProjectList items={ProjectData} />
      </div>
      <Contact />
    </Fragment>
  )
}

export default ProjectsPage
