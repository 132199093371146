import React from "react"
import styled from "styled-components"

import { navigate } from "gatsby"

const Button = props => {
  const goToPage = link => {
    navigate(`/${link}`)
  }
  return (
    <>
      {props.anchor ? (
        <ButtonWrapper
          aria-label={props.label}
          className="anchor hybrid-button"
          as="a"
          href={props.href}
        >
          {props.cta}
        </ButtonWrapper>
      ) : props.link ? (
        <ButtonWrapper
          onClick={() => goToPage(props.link)}
          className="hybrid-button"
          aria-label={props.label}
          type={props.type}
        >
          {props.cta}
        </ButtonWrapper>
      ) : (
        <ButtonWrapper
          className="hybrid-button"
          aria-label={props.label}
          type={props.type}
        >
          {props.cta}
        </ButtonWrapper>
      )}
    </>
  )
}

const ButtonWrapper = styled.button`
  width: 180px;
  position: relative;
  display: inline-block;
  padding: 0 20px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  color: #fff;
  font-size: 0.85rem;
  letter-spacing: 0.25rem;
  text-transform: uppercase;
  text-decoration: none;
  box-sizing: border-box;
  background-color: var(--ghostBlue);
  background-size: 400%;
  border-radius: 30px;
  border: none;
  z-index: 1;
  margin: 40px auto;

  &:hover {
    cursor: pointer;
    background-color: var(--ghostGreen);
    /*animation: animate 8s linear infinite;

    &::before {
      filter: blur(0.5px);
      opacity: 1;
      animation: animate 8s linear;
    } */
  }

  /*
  &::before {
    content: "";
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    z-index: -1;
    background: linear-gradient(
      95deg,
      var(--ghostBlue),
      var(--ghostSilver),
      var(--ghostGreen)
    );
    background-size: 400%;
    border-radius: 40px;
    opacity: 0;
    transition: 0.5s;
  }

  @keyframes animate {
    0% {
      background-position: 0%;
    }
    100% {
      background-position: 400%;
    }
  }
  */
`

export default Button
