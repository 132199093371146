import React from "react"
import { Link } from "gatsby"

import "./ap-button-styles.scss"

const ApProjectButton = props => {
  return (
    <Link to={props.link}>
      <a className="ap-project-btn">{props.children}</a>
    </Link>
  )
}

export default ApProjectButton
