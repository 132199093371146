import React from "react"

import ProjectButton from "../ap-project-button/ap-project-button"

import "./project-item.styles.scss"

const ProjectItem = ({
  video,
  image,
  title,
  description,
  longDescription,
  link,
}) => {
  return (
    <li className="ap-project-item">
      <div className="ap-project-image-container">
        <img src={image.value} alt={image.alt} />
      </div>
      <div className="ap-project-content">
        <h2>{title}</h2>
        <div className="ap-project-small-description">
          <p>{description}</p>
        </div>
      </div>
      <div className="ap-project-actions">
        <ProjectButton link={link.pageIdentifier}>
          <span>Explore Project</span>
        </ProjectButton>
      </div>
    </li>
  )
}

export default ProjectItem
