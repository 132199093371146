import React from "react"

import ProjectItem from "../Project-Item/project-item"

import "./project-list.styles.scss"

const ProjectList = ({ items }) => {
  return (
    <ul className="ap-projects-list">
      {items.map(item => (
        <ProjectItem {...item} />
      ))}
    </ul>
  )
}

export default ProjectList
